import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import MarkdownViewer from "@components/MarkdownViewer"
import ImageMeta from "../components/ImageMeta"
import TheaterVideo from "@components/Theater/TheaterVideo"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import { Accordion } from "../components/Accordion"

const ParentCorner = props => {
  const post = props.data.allUniquePagesJson.nodes[0]
  const language = "en"

  return (
    <SharedStateProvider>
      <Layout language={language} className="parent-corner">
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={props.location.pathname}
        />
        <div className="columns nav-spacer">
          <div className="column is-3"></div>
          <div className="column section">
            <h1 className="has-text-centered">{post.heading}</h1>
            <MarkdownViewer markdown={post.blurb} />
          </div>
          <div className="column is-3"></div>
        </div>

        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${post.theater.youtube}`}
          controls={true}
          playing={true}
          overVideo={true}>
          <ImageMeta
            cloudName="nuvolum"
            publicId={post.theater.image}
            width="auto"
            responsive></ImageMeta>
        </TheaterVideo>

        <div className="columns section parent-corner__faq">
          <div className="column is-3"></div>
          <div className="column">
            <h2 className="has-text-centered">{post.faqs.heading}</h2>
            {post.faqs.accordion.map((item, i) => (
              <Accordion
                key={i}
                visibleText={item.accordionItem.question}
                hiddenText={item.accordionItem.answer}
              />
            ))}
          </div>
          <div className="column is-3"></div>
        </div>

        <div className="columns section color-back parent-corner__talk">
          <div className="column is-3"></div>
          <div className="column">
            <h2 className="has-text-centered">{post.talk.heading}</h2>
            <MarkdownViewer markdown={post.talk.blurb} />

            <div className="parent-corner__talk-points">
              <MarkdownViewer markdown={post.talk.bulletPoints} />
            </div>
          </div>
          <div className="column is-3"></div>
        </div>

        <div className="columns section parent-corner__talk-video">
          <div className="column is-3"></div>
          <div className="column">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${post.talk.theater.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}>
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.talk.theater.image}
                width="auto"
                responsive></ImageMeta>
            </TheaterVideo>
          </div>
          <div className="column is-3"></div>
        </div>

        <div className="columns section color-back parent-corner__exparel">
          <div className="column is-3"></div>
          <div className="column">
            <ImageMeta cloudName="nuvolum" publicId={post.exparel.image} />
            <MarkdownViewer markdown={post.exparel.blurb} />
            <ButtonGroupMap isCentered buttons={post.exparel.buttons} />
          </div>
          <div className="column is-3"></div>
        </div>

        <div className="columns section parent-corner__info">
          <div className="column is-3"></div>
          <div className="column">
            <h2 className="has-text-centered">{post.info.heading}</h2>
            <MarkdownViewer markdown={post.info.content} />
            <ButtonGroupMap isCentered buttons={post.info.buttons} />
          </div>
          <div className="column is-3"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const parentCornerQuery = graphql`
  query parentQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaDescription
        metaTitle
        heading
        blurb
        theater {
          image
          youtube
        }
        faqs {
          heading
          accordion {
            accordionItem {
              question
              answer
            }
          }
        }
        talk {
          heading
          blurb
          bulletPoints
          theater {
            image
            youtube
          }
        }
        info {
          heading
          content
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
        exparel {
          image
          blurb
          buttons {
            button {
              buttonText
              href
              destination
              appearance
            }
          }
        }
      }
    }
  }
`

export default ParentCorner
